@use 'assets/styles/utils/helper' as h;
@use './theme.tokens' as t;

.softOptin {
  background: var(--notification-soft-optin--background-color);
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 20px;
  z-index: h.get-z-index('web-notification-softoptin');
  box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.15);
}

.close,
.closeButton {
  width: 17px;
  height: 17px;
}

.closeButton {
  border: none;
  padding: 0;
  background: none;
  position: absolute;
  right: 16px;
  top: 16px;

  &:hover {
    opacity: 0.7;
  }
}

.close {
  position: relative;

  &::before,
  &::after {
    content: ' ';
    position: absolute;
    height: 20px;
    width: 1px;
    background-color: var(--notification-soft-optin--color);
    left: 8px;
    top: -2px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.content {
  display: flex;
  padding-top: 29px;
}

.icon {
  padding: 12px 30px 0;

  img {
    margin: 0;
  }
}

.title {
  font-family: var(--notification-soft-optin--primary-font-family);
  font-size: var(--notification-soft-optin--title-font-size);
  font-weight: var(--notification-soft-optin--primary-font-weight);
  padding-right: 50px;
  margin: 0;
}

.description {
  font-family: var(--notification-soft-optin--secondary-font-family);
  font-size: var(--notification-soft-optin--desc-font-size);
  line-height: var(--notification-soft-optin--line-height);
  color: var(--notification-soft-optin--color);
  margin-top: 8px;
  padding-right: 24px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  font-family: var(--notification-soft-optin--primary-font-family);
  font-size: var(--notification-soft-optin--buttons-font-size);
  margin: 10px 20px 0 0;
}

.decline {
  text-transform: uppercase;
  border: none;
  background: none;
  color: var(--notification-soft-optin--color);
  flex-grow: 1;
  padding: 7px 30px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    color: var(--notification-soft-optin--color);
  }
}

.accept {
  text-transform: uppercase;
  border: none;
  color: var(--notification-soft-optin--accept-color);
  background-color: var(--notification-soft-optin--accept-background-color);
  flex-grow: 1;
  padding: 7px 30px;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    color: var(--notification-soft-optin--accept-color);
  }
}

@include h.breakpoint-m {
  .softOptin {
    top: 0;
    right: unset;
    bottom: unset;
    left: 22px;
    width: 375px;
    background-image: var(--notification-soft-optin--background-image);
    background-repeat: no-repeat;
    background-position: right bottom;
    padding: 0 0 var(--notification-soft-optin--padding-bottom);
    box-shadow: var(--notification-soft-optin--md-box-shadow);
  }

  .accept,
  .decline {
    padding: 7px 0;
  }
}
