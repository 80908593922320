@use 'assets/styles/utils/helper' as h;

:root {
  --notification-soft-optin--background-color: #f2f2f2;
  --notification-soft-optin--color: #2a2a2a;
  --notification-soft-optin--primary-font-family: inherit;
  --notification-soft-optin--primary-font-weight: inherit;
  --notification-soft-optin--secondary-font-family: inherit;
  --notification-soft-optin--title-font-size: var(--text-24);
  --notification-soft-optin--desc-font-size: var(--text-14);
  --notification-soft-optin--buttons-font-size: var(--text-16);
  --notification-soft-optin--line-height: var(--text-24);
  --notification-soft-optin--background-image: none;
  --notification-soft-optin--md-box-shadow: -1px 1px 1px 0 rgba(0, 0, 0, 0.15);
}

:global(body.msnbc) {
  --notification-soft-optin--accept-color: var(--notification-soft-optin--background-color);
  --notification-soft-optin--accept-background-color: #{h.$news-blue-40};
  --notification-soft-optin--primary-font-family: var(--founders-cond);
  --notification-soft-optin--secondary-font-family: var(--publico-txt);
  --notification-soft-optin--padding-bottom: 58px;
  --notification-soft-optin--background-image: url('./dots.svg');
}

:global(body.today) {
  --notification-soft-optin--background-color: #fff;
  --notification-soft-optin--primary-font-family: var(--secondary-font);
  --notification-soft-optin--secondary-font-family: var(--tertiary-font);
  --notification-soft-optin--primary-font-weight: 700;
  --notification-soft-optin--accept-color: var(--notification-soft-optin--background-color);
  --notification-soft-optin--accept-background-color: #{h.$today-orange-30};
  --notification-soft-optin--padding-bottom: 30px;
  --notification-soft-optin--md-box-shadow: 0 0 2px rgba(0, 0, 0, 0.25), 0 20px 20px rgba(0, 0, 0, 0.08);
}
